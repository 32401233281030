import { render, staticRenderFns } from "./FoodSalesTrend.vue?vue&type=template&id=1d610e12"
import script from "./FoodSalesTrend.vue?vue&type=script&lang=js"
export * from "./FoodSalesTrend.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d610e12')) {
      api.createRecord('1d610e12', component.options)
    } else {
      api.reload('1d610e12', component.options)
    }
    module.hot.accept("./FoodSalesTrend.vue?vue&type=template&id=1d610e12", function () {
      api.rerender('1d610e12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/sales-per-range/FoodSalesTrend.vue"
export default component.exports