import { render, staticRenderFns } from "./simple_graph.vue?vue&type=template&id=26da6b05"
import script from "./simple_graph.vue?vue&type=script&lang=js"
export * from "./simple_graph.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26da6b05')) {
      api.createRecord('26da6b05', component.options)
    } else {
      api.reload('26da6b05', component.options)
    }
    module.hot.accept("./simple_graph.vue?vue&type=template&id=26da6b05", function () {
      api.rerender('26da6b05', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Unviersal/simple_graph.vue"
export default component.exports