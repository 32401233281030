<template>
    <div class="kpi-box">
        <div class="body">
            <table class="table is-striped is-fullwidth is-narrow" @mouseover="showPopup" @mouseleave="hidePopup">
                <thead>
                    <tr class="grey-background">
                        <th></th>
                        <th class="header">Week</th>
                        <th>4 Week</th>
                        <th class="header">YTD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in visibleRows" :key="index">
                        <td class="sub-header">{{ row.label }}</td>
                        <td class="data-column text-left" v-bind="showMsfrToolTipText(row.week) ? { tooltiptext: getMsfrToolTipText('Week') } : {}">
                            <div class="inline-flex">
                                <div>{{ row.week }}</div>
                                <div v-if="row.label === 'Index to Goal'" v-html="icon_logic(row.week, 'Week')"></div>                         
                            </div>
                        </td>
                        <td class="data-column text-left" v-bind="showMsfrToolTipText(row.fourWeek) ? { tooltiptext: getMsfrToolTipText('4Week') } : {}">
                            <div class="inline-flex">
                                <div>{{ row.fourWeek }}</div>
                                <div v-if="row.label === 'Index to Goal'" v-html="icon_logic(row.fourWeek, '4 Week')"></div>       
                            </div>
                        </td>
                        <td class="data-column text-left" v-bind="showMsfrToolTipText(row.ytd) ? { tooltiptext: getMsfrToolTipText('YTD') } : {}">
                            <div class="inline-flex bold_ytd_value">
                                <div>{{ row.ytd }}</div>
                                <div v-if="row.label === 'Index to Goal'" v-html="icon_logic(row.ytd, 'YTD')"></div>       
                            </div>
                        </td>
                    </tr>
                    <tr v-if="popupVisible" v-for="(row, index) in hiddenRows" :key="'hidden-' + index">
                        <td class="sub-header">{{ row.label }}</td>
                        <td class="data-column text-left" v-bind="showMsfrToolTipText(row.week) ? { tooltiptext: getMsfrToolTipText('Week') } : {}">
                            <div class="inline-flex">
                                <div>{{ row.week }}</div>
                            </div>
                        </td>
                        <td class="data-column text-left" v-bind="showMsfrToolTipText(row.fourWeek) ? { tooltiptext: getMsfrToolTipText('4Week') } : {}">
                            <div class="inline-flex">
                                <div>{{ row.fourWeek }}</div>
                            </div>
                        </td>
                        <td class="data-column text-left" v-bind="showMsfrToolTipText(row.ytd) ? { tooltiptext: getMsfrToolTipText('YTD') } : {}">
                            <div class="inline-flex bold_ytd_value">
                                <div>{{ row.ytd }}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalBox',
    data() {
        return {
            popupVisible: this.globalPopupVisible,
            msfrToolTipTextWeek: 'Estimated Weekly Goal and Index, Calculated using the Weekly Shares from MSFR Goal',
            msfrToolTipText4Week: 'Estimated 4Week Goal and Index, Calculated using the weekly sales Shares from MSFR Goal',
            msfrToolTipTextYtd: 'Estimated YTD Goal and Index, Calculated using the weekly sales Shares from MSFR Goal'
        };
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        globalPopupVisible: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        globalPopupVisible(newVal) {
            this.popupVisible = newVal;
        }
    },
    methods: {
    icon_logic(value, header) {
        if (!value) {
            return '';
        }

        const values = value.split('/').map(v => parseFloat(v.trim()));

        const getIcon = (val, isYTD) => {
            if (val > 100) {
                return isYTD ? '<span class="icon has-text-success fas fa-circle"></span>' 
                : 
                '<span class="icon has-text-success fas fa-arrow-up"></span>';
            } else if (val < 100) {
                return isYTD ? '<span class="icon has-text-danger fas fa-circle"></span>' 
                : 
                '<span class="icon has-text-danger fas fa-arrow-down"></span>';
            } else {
                return '<span class="icon has-text-warning fas fa-minus"></span>';
            }
        };

        const isYTD = header === 'YTD';
        return values.map(val => getIcon(val, isYTD)).join('/');
    },
        showMsfrToolTipText(value) {
            if (value === '-' || value === '- / -') {
                return false;
            }

            if (this.name !== 'Sales') {
                return true;
            }
        },
        getMsfrToolTipText(period) {
            switch (period) {
                case 'Week':
                    return this.msfrToolTipTextWeek;
                case '4Week':
                    return this.msfrToolTipText4Week;
                case 'YTD':
                    return this.msfrToolTipTextYtd;
            }
        },
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        }
    },
    computed: {
        visibleRows() {
            return [
                { label: 'Actual', week: this.data.actuals, fourWeek: this.data.actuals_r4, ytd: this.data.actuals_fytd },
                { label: 'Index to Goal', week: this.data.index_to_goal, fourWeek: this.data.index_to_goal_r4, ytd: this.data.index_to_goal_ytd },
            ];
        },
        hiddenRows() {
            return [
                { label: 'Goal', week: this.data.goals, fourWeek: this.data.goals_r4, ytd: this.data.goals_fytd },
                { label: 'Gap to Goal', week: this.data.gap_to_goal, fourWeek: this.data.gap_to_goal_r4, ytd: this.data.gap_to_goal_ytd },
                { label: 'Index to LY', week: this.data.actuals_index, fourWeek: this.data.r4_index, ytd: this.data.ytd_index },
                { label: 'Goal Index to LY', week: this.data.goals_index, fourWeek: this.data.goals_r4_index, ytd: this.data.goals_fytd_index }
            ];
        }
    },
};
</script>

<style lang="scss" scoped>
.data-column {
    text-align: right;
    font-size: 12px;
}

.header {
    text-align: center !important;
    border-left: 1px solid white;
}

.kpi-box {
    background-color: #fff;
    height: 100%;
    position: relative;
    z-index: 1;

    .body {
        background-color: #fff;
    }
}

.kpi-box .table th {
    border-bottom: 2px solid #cce3ff;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
}

.kpi-box .table tr td {
    font-weight: 400;
    text-align: center;
}

.meta-desc {
    font-size: 0.8rem;
    padding-left: 0.5rem;
    text-align: left;
}

.name-column {
    width: 42%;
}

.set-width {
    width: 4em;
}

.sub-header {
    font-size: 12px;
    font-weight: bold !important;
    text-align: left !important;
}

.grey-background {
    font-size: 0.8rem;
    background-color: #e3e3e1;
}

table.table thead tr th {
    color: black !important;
}

.kpi-box .table tr .bold_ytd_value {
    font-weight: 600;
    font-size: 1em;
}

.ft-tooltip:after,
[tooltiptext]:after {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 200px;
}

.popup {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    top: 100%;
    left: 0;
    width: 100%;
    margin-bottom: 20px;
}

.table {
    position: relative;
}

.popup .table {
    margin-bottom: 20px;
}
</style>