var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kpi-box" }, [
    _c("div", { staticClass: "body" }, [
      _vm.data
        ? _c(
            "table",
            { staticClass: "table is-striped is-fullwidth is-narrow" },
            [
              _c("thead", [
                _c(
                  "tr",
                  { staticClass: "grey-background" },
                  _vm._l(
                    [
                      { label: _vm.title, value: 1 },
                      ..._vm.chunked_data[0][0].headers[0],
                    ],
                    function (header, header_key) {
                      return _c(
                        "th",
                        {
                          key: header_key,
                          class: { header: header_key !== 0 },
                          attrs: { colspan: header.value },
                        },
                        [_vm._v(" " + _vm._s(header.label) + " ")]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _c(
                "tbody",
                [
                  _vm._l(
                    _vm.chunked_data,
                    function (data_grouped_by_headers, i) {
                      return [
                        data_grouped_by_headers[0].headers[1]
                          ? _c(
                              "tr",
                              { key: i },
                              [
                                _c("td"),
                                _vm._l(
                                  data_grouped_by_headers[0].headers[1],
                                  function (sub_header, subheader_key) {
                                    return _c(
                                      "td",
                                      {
                                        key: `${i}-${subheader_key}-1`,
                                        staticClass: "sub-header",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: [
                                              sub_header.label === "blank"
                                                ? "is-invisible"
                                                : "",
                                            ],
                                          },
                                          [_vm._v(_vm._s(sub_header.label))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._l(data_grouped_by_headers, function (row, j) {
                          return _c(
                            "tr",
                            {
                              key: `${i}-${j}-2`,
                              staticClass: "cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.page_link(row.name)
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "name-column",
                                  class: `${
                                    row.version_tooltip ? "ft-tooltip" : ""
                                  }`,
                                  attrs: {
                                    tooltiptext: row.version_tooltip || "",
                                  },
                                },
                                [
                                  !row.parent
                                    ? _c(
                                        "span",
                                        { staticClass: "bold-kpi-name" },
                                        [_vm._v(_vm._s(row.name))]
                                      )
                                    : _c("span", [
                                        _vm._v("- " + _vm._s(row.name)),
                                      ]),
                                ]
                              ),
                              _vm._l(row.data_keys, function (value, k) {
                                return _c(
                                  "td",
                                  {
                                    key: `${i}-${k}-3`,
                                    staticClass: "data-column text-left",
                                  },
                                  [
                                    `icon_${value}` in row.icon_values
                                      ? _c(
                                          "div",
                                          { staticClass: "inline-flex" },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$helper.round(
                                                    row.measures[value],
                                                    null,
                                                    ","
                                                  )
                                                ) +
                                                  _vm._s(
                                                    row.measures[value]
                                                      ? row.append
                                                      : ""
                                                  )
                                              ),
                                            ]),
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.icon_logic(
                                                    row.icon_values,
                                                    value
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$helper.round(
                                                  row.measures[value],
                                                  null,
                                                  ","
                                                )
                                              ) +
                                              _vm._s(
                                                row.measures[value]
                                                  ? row.append
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]
          )
        : _c("div", { staticClass: "pt mt" }, [
            !_vm.$store.getters.check_access([
              _vm.title.toLowerCase(),
              "*",
              "r",
            ])
              ? _c("div", [_vm._v("You do not have access to this KPI")])
              : _c("div", [_vm._v("No data is available for this KPI.")]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }