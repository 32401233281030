var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kpi-box" }, [
    _c("div", { staticClass: "body" }, [
      _c(
        "table",
        {
          staticClass: "table is-striped is-fullwidth is-narrow",
          on: { mouseover: _vm.showPopup, mouseleave: _vm.hidePopup },
        },
        [
          _vm._m(0),
          _c(
            "tbody",
            [
              _vm._l(_vm.visibleRows, function (row, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "sub-header" }, [
                    _vm._v(_vm._s(row.label)),
                  ]),
                  _c(
                    "td",
                    _vm._b(
                      { staticClass: "data-column text-left" },
                      "td",
                      _vm.showMsfrToolTipText(row.week)
                        ? { tooltiptext: _vm.getMsfrToolTipText("Week") }
                        : {},
                      false
                    ),
                    [
                      _c("div", { staticClass: "inline-flex" }, [
                        _c("div", [_vm._v(_vm._s(row.week))]),
                        row.label === "Index to Goal"
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icon_logic(row.week, "Week")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "td",
                    _vm._b(
                      { staticClass: "data-column text-left" },
                      "td",
                      _vm.showMsfrToolTipText(row.fourWeek)
                        ? { tooltiptext: _vm.getMsfrToolTipText("4Week") }
                        : {},
                      false
                    ),
                    [
                      _c("div", { staticClass: "inline-flex" }, [
                        _c("div", [_vm._v(_vm._s(row.fourWeek))]),
                        row.label === "Index to Goal"
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icon_logic(row.fourWeek, "4 Week")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "td",
                    _vm._b(
                      { staticClass: "data-column text-left" },
                      "td",
                      _vm.showMsfrToolTipText(row.ytd)
                        ? { tooltiptext: _vm.getMsfrToolTipText("YTD") }
                        : {},
                      false
                    ),
                    [
                      _c("div", { staticClass: "inline-flex bold_ytd_value" }, [
                        _c("div", [_vm._v(_vm._s(row.ytd))]),
                        row.label === "Index to Goal"
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.icon_logic(row.ytd, "YTD")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ])
              }),
              _vm._l(_vm.hiddenRows, function (row, index) {
                return _vm.popupVisible
                  ? _c("tr", { key: "hidden-" + index }, [
                      _c("td", { staticClass: "sub-header" }, [
                        _vm._v(_vm._s(row.label)),
                      ]),
                      _c(
                        "td",
                        _vm._b(
                          { staticClass: "data-column text-left" },
                          "td",
                          _vm.showMsfrToolTipText(row.week)
                            ? { tooltiptext: _vm.getMsfrToolTipText("Week") }
                            : {},
                          false
                        ),
                        [
                          _c("div", { staticClass: "inline-flex" }, [
                            _c("div", [_vm._v(_vm._s(row.week))]),
                          ]),
                        ]
                      ),
                      _c(
                        "td",
                        _vm._b(
                          { staticClass: "data-column text-left" },
                          "td",
                          _vm.showMsfrToolTipText(row.fourWeek)
                            ? { tooltiptext: _vm.getMsfrToolTipText("4Week") }
                            : {},
                          false
                        ),
                        [
                          _c("div", { staticClass: "inline-flex" }, [
                            _c("div", [_vm._v(_vm._s(row.fourWeek))]),
                          ]),
                        ]
                      ),
                      _c(
                        "td",
                        _vm._b(
                          { staticClass: "data-column text-left" },
                          "td",
                          _vm.showMsfrToolTipText(row.ytd)
                            ? { tooltiptext: _vm.getMsfrToolTipText("YTD") }
                            : {},
                          false
                        ),
                        [
                          _c(
                            "div",
                            { staticClass: "inline-flex bold_ytd_value" },
                            [_c("div", [_vm._v(_vm._s(row.ytd))])]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e()
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "grey-background" }, [
        _c("th"),
        _c("th", { staticClass: "header" }, [_vm._v("Week")]),
        _c("th", [_vm._v("4 Week")]),
        _c("th", { staticClass: "header" }, [_vm._v("YTD")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }