import { render, staticRenderFns } from "./NotificationsAccordion.vue?vue&type=template&id=ed84dc4a&scoped=true"
import script from "./NotificationsAccordion.vue?vue&type=script&lang=js"
export * from "./NotificationsAccordion.vue?vue&type=script&lang=js"
import style0 from "./NotificationsAccordion.vue?vue&type=style&index=0&id=ed84dc4a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed84dc4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed84dc4a')) {
      api.createRecord('ed84dc4a', component.options)
    } else {
      api.reload('ed84dc4a', component.options)
    }
    module.hot.accept("./NotificationsAccordion.vue?vue&type=template&id=ed84dc4a&scoped=true", function () {
      api.rerender('ed84dc4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/NotificationsAccordion.vue"
export default component.exports