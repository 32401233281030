import { render, staticRenderFns } from "./Announcement.vue?vue&type=template&id=30d03870&scoped=true"
import script from "./Announcement.vue?vue&type=script&lang=js"
export * from "./Announcement.vue?vue&type=script&lang=js"
import style0 from "./Announcement.vue?vue&type=style&index=0&id=30d03870&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d03870",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30d03870')) {
      api.createRecord('30d03870', component.options)
    } else {
      api.reload('30d03870', component.options)
    }
    module.hot.accept("./Announcement.vue?vue&type=template&id=30d03870&scoped=true", function () {
      api.rerender('30d03870', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/presentation-mode/Announcement.vue"
export default component.exports