import { render, staticRenderFns } from "./SwitchBox.vue?vue&type=template&id=de2d83a8&scoped=true"
import script from "./SwitchBox.vue?vue&type=script&lang=js"
export * from "./SwitchBox.vue?vue&type=script&lang=js"
import style0 from "./SwitchBox.vue?vue&type=style&index=0&id=de2d83a8&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2d83a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de2d83a8')) {
      api.createRecord('de2d83a8', component.options)
    } else {
      api.reload('de2d83a8', component.options)
    }
    module.hot.accept("./SwitchBox.vue?vue&type=template&id=de2d83a8&scoped=true", function () {
      api.rerender('de2d83a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/SwitchBox.vue"
export default component.exports