<template>
<div class="custom-title">
    <div class="title">
        {{title}}
        <div class="cursor-pointer inline-block" @click="show_kpi">
            <i v-show="!this.$store.getters.presentation_mode" class="fas fa-info-circle superscript text-ikea-blue"></i>
        </div>
        <!-- <div class="cursor-pointer inline-block ml05 tooltip transform-none"
            @click="bookmark"
            :data-tooltip="!is_bookmarked ? `Save ${title} as a favourite.` : `Remove ${title} from your favourites.`">
            <i v-show="!this.$store.getters.presentation_mode" class="fas fa-star superscript" :class="{'text-ikea-yellow': is_bookmarked }"></i>
        </div> -->
    </div>

    <div v-show="show_kpi_defintion">
        <div v-if="is_loaded.kpi_definition">
            <div v-if="kpi_definition">
                <b>Definition:</b> <div class="column ProseMirror" v-html="kpi_definition"/>
                <hr>
                <DefinitionRatingButtons v-if="this.definition_id" :id="this.definition_id" :current="this.rating"/>
            </div>
        </div>
        <div v-else>
            <b>Definition:</b> <div class="column ProseMirror">
                This KPI definition hasn't been defined.
            </div>
        </div>
    </div>

    <div class="">
        <div class="subtitle pbp5" v-if="subtitle">
            <p>{{subtitle}}</p>
        </div>
    
        <div v-show="!hide_contact_info" class="subtitle" v-if="is_loaded.kpi_owner && this.title !== 'Market View' && this.title !== 'VAPS Performance'">
            <b>KPI Accountable</b>: {{kpi_owner}}
        </div>

        <div v-show="!hide_contact_info" class="subtitle" v-if="is_loaded.kpi_owner && this.title !== 'Market View' && this.title !== 'VAPS Performance'">
            <b>KPI Contact</b>: {{kpi_contact}}
        </div>
        

        <!-- the below code will be not displayed if the request is coming from pbi_embed component -->
        <div class="subtitle" v-if="!from_pbi && last_updated">
            <b>Last updated:</b> {{last_updated}}
        </div>

        <div class="subtitle" v-if="load_tracker_data && load_tracker_data.status_indicator">
           <!-- <b>Load status: </b>
             <span :class="[load_tracker_data.status_indicator]"><i class="fas fa-circle"></i></span>
            {{load_tracker_data.status_description}} -->
            <!-- <span v-if="load_tracker_data.load_comment">, <span class="load-comment-wrapper">{{load_tracker_data.load_comment}}</span></span> -->
        </div>

        <div v-if="show_view_more">
            <!-- <div class="subtitle" v-if="load_tracker_data && load_tracker_data.processing_end">
                <b>Next update by:</b> {{load_tracker_data.processing_end.toUTCString().slice(0,16) || 'N/A'}}
            </div> -->
            
            <div class="subtitle" v-if="load_tracker_data && load_tracker_data.frequency_label">
                <b>Frequency:</b> {{load_tracker_data.frequency_label}}
            </div> 
        </div>
        
        <div v-if="load_tracker_data"
            @click="show_view_more = !show_view_more"
            class="view-more">
            <span v-if="show_view_more">View less <i class="fas fa-chevron-up"></i></span>
            <span v-else>View more <i class="fas fa-chevron-down"></i></span>
        </div>
    
    </div>

</div>
</template>

<script>
import { Bus } from '@/event-bus';
import load_tracker_helper from '@/helper/load-tracker-helpers.js';
import DefinitionRatingButtons from '@/components/ui/DefinitionRatingButtons.vue';

export default {
    components: {
        DefinitionRatingButtons
    },
    props: {
        title: String,
        subtitle: String,
        location: String,
        version_api_url: String,
        from_pbi: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            excluded_locations: [
                'by-market',
                'detail',
                'summary',
                'top-8',
                'tree',
                'trend'
            ],
            rating: null,
            location_id: null,
            load_tracker_data: null,
            kpi_definition: false,
            kpi_owner: null,
            kpi_contact: null,
            is_bookmarked: false,
            is_loaded: {
                subtitle: false,
                kpi_definition: false,
                kpi_owner: false,
                kpi_contact: false
            },
            last_updated: 'N/A',
            show_kpi_defintion: null,
            show_kpi_owner: null,
            show_view_more: false,
            hide_contact_info: this.location.includes('country-overview')
        }
    },
    mounted: function() {
        // this.check_if_bookmarked();
        this.set_title();
        this.set_location();
    },
    destroyed: function() {
        this.remove_title();
    },
    methods: {
        bookmark: function() {
            this.$http.post('force', `/bookmarks/${this.is_bookmarked ? 'remove' : 'add'}`, {
                location: this.$helper.location()
            }).then(
                succ => {
                    this.check_if_bookmarked();
                    Bus.$emit('bookmarked');
                },
                this.$helper.error
            );
        },
        check_if_bookmarked: function() {
            this.$http.post('force', '/bookmarks/check', {
                location: this.$helper.location()
            }).then(
                succ => this.is_bookmarked = succ.data.is_bookmarked,
                this.$helper.error
            );
        },
        format_location: function(location) {
            let new_location = {
                kpi: location.kpi,
                kpi_area: location.kpi_area,
                market_area: !this.excluded_locations.includes(location.market_area) ? location.market_area : null,
                store_area: null
            };
            if (new_location.kpi_area === 'sales-planning-precision') new_location.market_area = null;
            return new_location;
        },
        remove_title: function() {
            document.title = 'Flight Tower';
        },
        set_location: function() {
            if (!this.location) return;
            
            this.is_loaded = {
                kpi_definition: false,
                kpi_owner: false,
                kpi_contact: false
            };

            this.load_kpi_area();
            this.load_kpi_load_tracker_information();
            this.load_definition(this.$helper.location());
            this.load_kpi_owner(this.$helper.location());
            this.load_kpi_contact(this.$helper.location());
        },
        set_title: function() {
            document.title = `${this.title} | Flight Tower`;
        },
        load_definition: function(location) {
            const definition_location = this.format_location(location);
            if (definition_location.kpi_area === 'gross-margin-per-country') definition_location.market_area = null;

            this.$http.post('force','/admin/kpi-definition/load', {
                location: definition_location
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || d.length === 0) return;
                    
                    const definition = succ.data.pop();
                    this.rating = definition.rating;
                    this.kpi_definition = definition.definition;
                    this.definition_id = definition.id;
                    this.is_loaded.kpi_definition = true;
                },
                this.$helper.error
            );
        },
        load_kpi_load_tracker_information: function() {
            const kpi_code = !!this.version_api_url ? `fs#${this.version_api_url.split('/')[0]}` : this.$helper.page_name(this.$router);
            if(kpi_code)
            {
                this.$http.get(`/data-load-tracker/find-by-location/${encodeURIComponent(kpi_code)}`)
                .then(
                    succ => {
                        if (succ.data) this.load_tracker_data = load_tracker_helper.process_load_tracker_table_row(succ.data);
                    },
                    this.$helper.error
                );
            }
        },
        load_kpi_owner: function(location) {
            this.kpi_owner = null;
            const owner_location = this.format_location(location);
            this.$http.post('/admin/kpi-owners/load', {
                location: owner_location
            }).then(
                succ => {
                    if (succ.data.length > 0) {
                        this.kpi_owner = succ.data[0].display_name;
                        this.is_loaded.kpi_owner = true;
                    } else {
                        this.kpi_owner = "N/A";
                        this.is_loaded.kpi_owner = true;
                    }  
                },
                this.$helper.error
            );
        },
        load_kpi_contact: function(location) {
            this.kpi_contact = null;
            const contact_location = this.format_location(location);
            this.$http.post('/admin/kpi-contacts/load', {
                location: contact_location
            }).then(
                succ => {
                    if (succ.data.length > 0) {
                        this.kpi_contact = succ.data[0].display_name;
                        this.is_loaded.kpi_contact = true;
                    } else {
                        this.kpi_contact = "N/A";
                        this.is_loaded.kpi_contact = true;
                    }  
                },
                this.$helper.error
            );
        },
        load_kpi_area: function() {
            const name = this.$helper.page_name(this.$router);
            if (this.version_api_url) {
                this.$http.get(`/version/${this.version_api_url}`)
                .then(
                    succ => {
                        if (succ && succ.data) {
                            this.last_updated = this.$helper.format_fs_version(succ.data, this.title);
                        } else {
                            this.last_updated = 'N/A';
                        }
                    },
                    this.$helper.error
                );
                return;
            } else if (name) {
                this.$http.get(`/last-updated/${name}`).then(
                    succ => {
                        let d = succ.data;
                        if (d.length === 0) {
                            this.$store.state.global.last_updated = 'N/A';
                            this.last_updated = 'N/A';
                        } else {
                            let { version } = succ.data.pop();
                            this.$store.state.global.last_updated = version;
                            this.last_updated = version;
                        }
                        this.is_loaded.subtitle = true;
                    },
                    this.$helper.error
                );
            } else {
                this.$store.state.global.last_updated = 'N/A';
                this.last_updated = 'N/A';
            }
        },
        show_kpi: function() {
            this.show_kpi_defintion = !this.show_kpi_defintion;
        }
    },
    watch: {
  title(newTitle) {
    this.set_title(newTitle);
  },
  location() {
    this.set_location();
    this.set_title();
  }
}
}
</script>

<style scoped>
hr {
    padding: 0em;
    margin: 1em;    
}
.custom-title {
    color: #000;
    margin: 1em;
    text-align: left;
    width: calc(100% - 2em);
}

.custom-title .title {
    text-transform: uppercase;
}

.custom-title .subtitle {
    color: #000;
    font-size: 1rem;
    margin: 0;
}

.load-comment-wrapper {
    color: #484848;
}

.title {
    margin-bottom: 1rem !important;
}

.view-more {
    color: #0051ba;
    cursor: pointer;
}
.view-more:hover {
    color: #014297;
}
</style>