<template>
    <div class="is-multiline global-container">
        <div
            v-for="(box, index) in kpi_boxes"
            :key="index"
            class="global-box"
            @mouseover="handleHover(box.name)"
            @mouseleave="handleHoverLeave"
        >
            <div class="kpibox-shadow one-hundred-height">
                <!-- heading -->
                <div
                    class="kpibox-header grey columns content_align"
                    @mouseover="showPopup"
                    @mouseleave="handleHoverLeave"
                >
                    <HomeGlobalIcons :icon="box.name" />
                    <div v-if="box.sub_categories.length > 1" class="column kpibox-text global_header">
                        {{ box.name }} {{ box.sub_categories.map(subCategory => subCategory.name).join(' / ') }}
                        <span v-if="getKpiData(box).formatted_text" class="formatted-text">
                            ({{ getKpiData(box).formatted_text }})
                        </span>
                    </div>
                    <div v-else class="column kpibox-text global_header">
                        {{ box.name }}
                        <span v-if="getKpiData(box).formatted_text" class="formatted-text">
                            ({{ getKpiData(box).formatted_text }})
                        </span>
                    </div>
                </div>
                <!-- Single table for categories without sub-categories -->
                <div class="kpi-table">
                    <!-- Pass the data based on the main box location -->
                    <GlobalBox
                        :data="getKpiData(box)"
                        :name="box.name"
                        :globalPopupVisible="hoveredBox && hoveredBox.includes(box.name)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GlobalBox from '@/views/global_box.vue';
import HomeGlobalIcons from '@/components/bespoke/home/HomeGlobalIcons.vue';

export default {
    components: {
        GlobalBox,
        HomeGlobalIcons
    },
    data() {
        return {
            kpi_data: [], // Changed to an array to hold multiple KPIs
            kpi_render: false,
            hoveredBox: null,
            kpi_boxes: [
                {
                    name: 'Sales',
                    sub_categories: [],
                    location: 'Sales'
                },
                {
                    name: 'Quantity',
                    sub_categories: [],
                    location: 'Quantity'
                },
                {
                    name: 'Customer',
                    sub_categories: [
                        { name: 'Offline', location: 'Customers - Total Store' },
                        { name: 'Online', location: 'Customers - Online' }
                    ]
                },
                {
                    name: 'Visitation',
                    sub_categories: [
                        { name: 'Offline', location: 'Visitation - Total Store' },
                        { name: 'Online', location: 'Visitation - Online' }
                    ]
                }
            ]
        };
    },
    mounted: function () {
        this.load_kpi_data();
    },
    methods: {
        load_kpi_data: function () {
            if (this.$store.state.selected.market === 'TI') return;
            this.kpi_render = false;

            this.$http
                .post('force', '/landing-page/landing-boxes', { market: this.$store.getters.market })
                .then(succ => {
                    if (!succ.data || !Object.keys(succ.data).length) {
                        this.kpi_data = this.reset_kpi_data();
                    } else {
                        this.kpi_data = this.formatKpiData(succ.data); // Format the data
                    }
                    this.kpi_render = true;
                }, this.$helper.error);
        },

        formatKpiData(data) {
            return data.map(kpi => ({
                ...kpi,
                ytd_index: this.formatValue(kpi.ytd_index, kpi.kpi_name),
                actuals_index: this.formatValue(kpi.actuals_index, kpi.kpi_name),
                r4_index: this.formatValue(kpi.r4_index, kpi.kpi_name),
                actuals_fytd: this.formatValue(kpi.actuals_fytd, kpi.kpi_name),
                actuals: this.formatValue(kpi.actuals, kpi.kpi_name),
                actuals_r4: this.formatValue(kpi.actuals_r4, kpi.kpi_name),
                goals_fytd: this.formatValue(kpi.goals_fytd, kpi.kpi_name),
                goals: this.formatValue(kpi.goals, kpi.kpi_name),
                goals_r4: this.formatValue(kpi.goals_r4, kpi.kpi_name),
                goals_index: this.formatValue(kpi.goals_index, kpi.kpi_name),
                goals_r4_index: this.formatValue(kpi.goals_r4_index, kpi.kpi_name),
                goals_fytd_index: this.formatValue(kpi.goals_fytd_index, kpi.kpi_name),
                index_to_goal: this.formatValue(kpi.index_to_goal, kpi.kpi_name),
                index_to_goal_r4: this.formatValue(kpi.index_to_goal_r4, kpi.kpi_name),
                index_to_goal_ytd: this.formatValue(kpi.index_to_goal_ytd, kpi.kpi_name),
                gap_to_goal: this.formatValue(kpi.gap_to_goal, kpi.kpi_name),
                gap_to_goal_r4: this.formatValue(kpi.gap_to_goal_r4, kpi.kpi_name),
                gap_to_goal_ytd: this.formatValue(kpi.gap_to_goal_ytd, kpi.kpi_name)
            }));
        },

        formatValue(value, kpi_name) {
            if (value === null) {
                return '-';
            }

            if (typeof value === 'string') {
                value = parseFloat(value);
            }

            if (typeof value !== 'number' || isNaN(value)) {
                return '-';
            }

            // Format numbers with thousand separators
            const formatWithCommas = num => {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            };

            // Round to one decimal place
            const roundToOneDecimal = num => {
                return parseFloat(num).toFixed(1);
            };

            let formattedValue;

            // Check ranges using absolute value to handle both positive and negative numbers
            if (Math.abs(value) >= 1000000) {
                formattedValue = roundToOneDecimal(value / 1000000) + 'M'; // Round to one decimal place and add 'M'
            } else if (Math.abs(value) >= 1000) {
                formattedValue = roundToOneDecimal(value / 1000) + 'K'; // Round to one decimal place and add 'K'
            } else {
                const valueStr = value.toString();
                if (valueStr.includes('.') && valueStr.split('.')[1].length === 1) {
                    return formatWithCommas(valueStr);
                }
                formattedValue = roundToOneDecimal(value).toString();
            }

            if (formattedValue.includes('K') || formattedValue.includes('M')) {
                return formatWithCommas(formattedValue);
            }
            return formatWithCommas(formattedValue);
        },

        getKpiData(box) {
            if (box.sub_categories.length > 0) {
                let subData = [];
                box.sub_categories.map(element => {
                    subData.push(this.kpi_data.find(kpi => kpi.kpi_name === element.location) || {});
                });
                let combinedData = subData.reduce((acc, cur) => {
                    for (let key in cur) {
                        if (cur.hasOwnProperty(key)) {
                            acc[key] = (acc[key] ? acc[key] + ' / ' : '') + cur[key];
                        }
                    }
                    return acc;
                }, {});
                if (subData[0] && subData[0].formatted_text) {
                    combinedData.formatted_text = subData[0].formatted_text;
                }
                return combinedData;
            } else {
                let boxData = this.kpi_data.find(kpi => kpi.kpi_name === box.location) || {};
                return { ...boxData, formatted_text: boxData.formatted_text };
            }
        },

        handleHover(boxName) {
            if (boxName === 'Sales') {
                this.hoveredBox = ['Sales', 'Quantity'];
            } else if (boxName === 'Quantity') {
                this.hoveredBox = ['Sales', 'Quantity'];
            } else if (boxName === 'Customer') {
                this.hoveredBox = ['Customer', 'Visitation'];
            } else if (boxName === 'Visitation') {
                this.hoveredBox = ['Customer', 'Visitation'];
            } else {
                this.hoveredBox = null;
            }
        },

        handleHoverLeave() {
            this.hoveredBox = null;
        },

        showPopup() {
            this.hoveredBox = [];
        },
    },
    computed: {
        stores: function () {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                };
            }
        }
    },
    watch: {
        '$store.getters.market': function (newMarket, oldMarket) {
            this.store = null;
            this.load_kpi_data();
        }
    }
};
</script>

<style lang="scss" scoped>
.global_box {
    padding: 0;
}

.content_align {
    justify-content: center;
}

.global_header {
    margin: auto;
    font-weight: bold;
    font-size: 1em;
    color: #161515;
    justify-content: space-between;
}

.formatted-text {
    margin-left: 10px;
}

.sub-categories-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sub-category h3 {
    font-weight: 600;
    text-align: center;
    padding: 5px 0;
    color: #363636;
}

.global-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.global-box {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .global-container {
        display: block;
        padding: 10px;
        margin-right: 30px;
    }

    .some-element {
        display: block;
    }

    .another-element {
        margin-top: 20px;
    }

    .global-box {
        display: block;
        margin-bottom: 30px;
    }
}
</style>