<template>
    <div>
        <AdminUniversal component="title" :props="title_props" />

        <div class="options-bar text-left dynamic_front_page">
            <div class="search_kpi_box">
                <label class="label">Title</label>
                <input type="text" class="input" v-model="title_input" placeholder="Title" />

                <label class="label">Search KPI</label>
                <v-select
                    v-if="selectable_kpis"
                    :options="selectable_kpis"
                    :reduce="kpi => kpi.kpi_name"
                    label="kpi_name"
                    @input="selected_kpi = $event"
                    @open="showNotification = false"
                    class="custom-dropdown"
                />
                <button
                    class="button is-success"
                    @click="add_kpi"
                    :disabled="getAllKpiNames().length > max_kpis"
                    :title="getAllKpiNames().length > max_kpis ? `Can max select ${max_kpis + 1} KPIs` : ''"
                >
                    Add
                </button>
                <Notification v-if="showNotification" :message="'This KPI has already been added.'" />
            </div>
            <div class="custom_kpi_box">
                <label class="label">Preview</label>
                <div class="column kpibox" :key="1" :id="1">
                    <div class="kpibox-shadow one-hundred-height">
                        <div class="kpibox-header ikea-yellow columns">
                            <div class="column kpibox-text" v-html="title"></div>
                        </div>

                        <div class="dynamic_front_page_kpis" v-if="kpi_data && Object.keys(kpi_data).length">
                            <div v-for="(update_frequency, i) in frequencies" :key="i">
                                <KPIBox
                                    v-if="kpi_data && kpi_data[update_frequency]"
                                    :title="update_frequency"
                                    :box_name="'box.name'"
                                    :data="kpi_data[update_frequency]"
                                />
                            </div>
                        </div>
                        <div v-else>No data found</div>
                    </div>
                </div>
            </div>
            <br />
            <div class="selected-kpis-row">
                <div class="selected-kpis-box">
                    <label class="label">Selected KPIs</label>
                    <div v-if="getAllKpiNames().length" class="selected-kpis-list">
                        <div v-for="(kpi, index) in getAllKpiNames()" :key="index" class="selected-kpi-item">
                            <span class="kpi-name">{{ kpi }}</span>
                            <button class="button is-small is-danger" @click="removeKpi(kpi)">
                                <span class="icon">
                                    <i class="fas fa-times"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="no-kpis">No KPIs selected</div>
                </div>
            </div>
        </div>

        <hr />
    </div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import KPIBox from '@/components/bespoke/home/KPIBox.vue';
import Notification from '@/components/ui/Notification.vue';

export default {
    components: {
        AdminUniversal,
        vSelect,
        Notification,
        KPIBox
    },
    data() {
        return {
            title_props: {
                title: 'Additional KPIs selected by the county'
            },
            selectable_kpis: null,
            selected_kpi: null,
            kpi_data: {},
            max_kpis: 3,
            frequencies: [],
            title_input: '',
            showNotification: false,
        };
    },
    mounted() {
        this.load_data();
    },
    computed: {
        title() {
            return this.title_input || 'Additional KPIs selected by the county';
        },
        market() {
            return this.$store.getters.market === null ? 'All' : this.$store.getters.market;
        }
    },
    watch: {
        '$store.getters.market': function (newMarket, oldMarket) {
            this.update_view();
        }
    },
    methods: {
        async update_view() {
            let savedSettings = await this.$http.get(
                'force',
                `/landing-page/dynamic-front-page/${this.market}`
            );

            if (savedSettings && savedSettings.data) {
                const data = savedSettings.data;
                this.title_input = data.title;
                this.kpi_data = data.kpis;
                this.frequencies = Object.keys(data.kpis);
            }
        },
        async removeKpi(kpi_name) {
            // Iterate over each frequency category in kpi_data
            Object.keys(this.kpi_data).forEach(frequency => {
                // Filter out the KPI with the matching kpi_name in each frequency category
                this.kpi_data[frequency] = this.kpi_data[frequency].filter(kpi => kpi.name !== kpi_name);
            });
            await this.save_settings();
        },
        getAllKpiNames() {
            // Use Object.keys to iterate over each frequency category
            return Object.keys(this.kpi_data).flatMap(frequency => {
                // Map over each KPI in the frequency category to get the name
                return this.kpi_data[frequency].map(kpi => kpi.name);
            });
        },
        async save_settings() {
            const payload = {
                title: this.title,
                kpis: this.getAllKpiNames(),
                market: this.market
            };
            await this.$http.post('force', '/landing-page/dynamic-front-page', payload);
            await this.update_view();
        },
        async add_kpi() {
            if (this.selected_kpi) {
                const selectedKpiData = this.selectable_kpis.find(kpi => kpi.kpi_name === this.selected_kpi);
                const alreadyAdded = this.getAllKpiNames().includes(this.selected_kpi);

                if (selectedKpiData && !alreadyAdded) {
                    this.showNotification = false;
                    const { kpi_name, frequency } = selectedKpiData;
                    const toAdd = {
                        name: kpi_name,
                        frequency: frequency
                    };

                    if (!this.kpi_data[frequency]) {
                        this.kpi_data[frequency] = [];
                    }

                    this.kpi_data[frequency].push(toAdd);
                    await this.save_settings();
                }
                else {
                    this.showNotification = true;
                }
            }
        },
        async load_data() {
            let response = await this.$http.post('force', '/landing-page/dynamic-front-page/get-available-kpis', {
                market: this.market
            });
            if (response.data && Array.isArray(response.data)) {
                await this.update_view();
            }
            this.selectable_kpis = response.data;
        }
    }
};
</script>

<style scoped>
.dynamic_front_page {
    display: flex;
    justify-content: baseline;
    justify-items: center;
}

.search_kpi_box {
    width: 30rem;
}

.custom_kpi_box {
    width: 50rem;
    margin-left: 2rem;
    font-size: 1.5em !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
}

.kpio-form {
    margin-top: 1em;
    text-align: left;
}

table {
    width: 100%;
}

.user-search {
    margin-left: -16px;
    max-width: 500px;
}

.custom-dropdown {
    background-color: #f9f9f9;
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1.75em;
}

/* Custom styles for vue-select */
.custom-dropdown .vs__dropdown-toggle {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
}

.custom-dropdown .vs__dropdown-toggle:hover {
    border-color: #007bff;
}

.custom-dropdown .vs__dropdown-menu {
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.custom-dropdown .vs__selected {
    color: #333;
    font-weight: bold;
}

.custom-dropdown .vs__dropdown-option--selected {
    background-color: #007bff;
    color: #fff;
}

.custom-dropdown .vs__dropdown-option--highlight {
    background-color: #e9ecef;
}

.column is-3-desktop {
    flex: 1 !important;
}
.dropdown-background {
    width: 30rem;
    height: 3.25rem;
    margin: auto;
    background: rgb(0, 81, 186);
    transform: perspective(1rem) rotateX(-1deg);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 3px 3px 9px #4a4a4a9e;
}
.dropdown-area {
    padding-top: 0.5rem;
    transform: perspective(2rem) rotateX(2deg);
}

.insights-box {
    width: calc(100vw - 1.5rem);
    margin-left: 0.75rem;
    margin-right: 0.75rem !important;
    margin-top: 1rem;
    padding: 1rem;
}

.insights-header {
    height: 4rem;
    margin-left: 0 !important;
}
.insights-subtitle {
    color: black;
    text-align: left;
    margin-left: 2.25rem;
    padding-top: 0.75rem;
}
.kpibox-header {
    color: black !important;
    margin: 0 !important;
    min-height: 4rem;
}
.kpibox-shadow {
    background-color: #ffffff;
    box-shadow: 3px 3px 9px #4a4a4a9e;
}
.kpibox-text {
    display: flex !important;
    margin: auto;
    align-items: center;
}
.kpibox {
    padding-top: 1.5rem !important;
}
.text-italics {
    font-size: 1.3rem;
}

.sales_trend_total_graph {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.selected-kpis-row {
    margin-top: 20px;
    padding: 10px;
}
.selected-kpis-box {
    margin: 1rem 0;
}
.selected-kpis-list {
    max-height: 600px;
    overflow-y: auto;
}
.selected-kpi-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 0.25rem 0;
    background: #f5f5f5;
}
.kpi-name {
    font-size: 0.9rem;
}
.button.is-danger {
    margin-left: 1rem;
}

.button:not(.is-small) {
    margin-bottom: 2rem;
    width: 100%;
}

.dynamic_front_page_kpis {
    min-height: 550px;
    overflow-y: auto;
    margin-bottom: 12px !important;
}

.dynamic_front_page_kpis .text-left {
    width: 70px !important;
}

.dynamic_front_page_kpis .text-left:last-child {
    font-size: 0.75em !important;
    width: 80px !important;
    padding-top: 0.5rem;
}
</style>