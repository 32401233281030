var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.presentation_mode > 0
        ? _c("PresentationMode")
        : _c("div", [
            _vm.loggedIn
              ? _c(
                  "div",
                  [
                    !_vm.$store.state.global.bot_mode
                      ? _c("MenuBar")
                      : _vm._e(),
                    _c("AccessRequest"),
                    _c("BrowserNotice"),
                    _c("div", { staticClass: "columns is-gapless" }, [
                      _c(
                        "div",
                        {
                          staticClass: "column",
                          class: _vm.computed_comments_class,
                        },
                        [
                          _c("Spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$store.state.loading !== 0,
                                expression: "$store.state.loading !== 0",
                              },
                            ],
                          }),
                          _c(
                            "div",
                            {
                              class: {
                                "max-width-content-wrapper": _vm.limit_width,
                              },
                            },
                            [_c("router-view"), _c("NPSForm")],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.$store.state.global.bot_mode &&
                      _vm.$store.state.commenting.width !== 0 &&
                      _vm.$store.state.global.show_observations
                        ? _c(
                            "div",
                            {
                              staticClass: "column",
                              class: `is-${_vm.$store.state.commenting.width}`,
                            },
                            [_c("Comments")],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "columns is-gapless" },
                  [
                    _vm._v('"> '),
                    _c("Spinner", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.loading !== 0,
                          expression: "$store.state.loading !== 0",
                        },
                      ],
                    }),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }