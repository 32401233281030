var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("div", { staticClass: "options-bar text-left dynamic_front_page" }, [
        _c(
          "div",
          { staticClass: "search_kpi_box" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Title")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.title_input,
                  expression: "title_input",
                },
              ],
              staticClass: "input",
              attrs: { type: "text", placeholder: "Title" },
              domProps: { value: _vm.title_input },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.title_input = $event.target.value
                },
              },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("Search KPI")]),
            _vm.selectable_kpis
              ? _c("v-select", {
                  staticClass: "custom-dropdown",
                  attrs: {
                    options: _vm.selectable_kpis,
                    reduce: (kpi) => kpi.kpi_name,
                    label: "kpi_name",
                  },
                  on: {
                    input: function ($event) {
                      _vm.selected_kpi = $event
                    },
                    open: function ($event) {
                      _vm.showNotification = false
                    },
                  },
                })
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button is-success",
                attrs: {
                  disabled: _vm.getAllKpiNames().length > _vm.max_kpis,
                  title:
                    _vm.getAllKpiNames().length > _vm.max_kpis
                      ? `Can max select ${_vm.max_kpis + 1} KPIs`
                      : "",
                },
                on: { click: _vm.add_kpi },
              },
              [_vm._v(" Add ")]
            ),
            _vm.showNotification
              ? _c("Notification", {
                  attrs: { message: "This KPI has already been added." },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "custom_kpi_box" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Preview")]),
          _c(
            "div",
            { key: 1, staticClass: "column kpibox", attrs: { id: 1 } },
            [
              _c("div", { staticClass: "kpibox-shadow one-hundred-height" }, [
                _c(
                  "div",
                  { staticClass: "kpibox-header ikea-yellow columns" },
                  [
                    _c("div", {
                      staticClass: "column kpibox-text",
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                  ]
                ),
                _vm.kpi_data && Object.keys(_vm.kpi_data).length
                  ? _c(
                      "div",
                      { staticClass: "dynamic_front_page_kpis" },
                      _vm._l(_vm.frequencies, function (update_frequency, i) {
                        return _c(
                          "div",
                          { key: i },
                          [
                            _vm.kpi_data && _vm.kpi_data[update_frequency]
                              ? _c("KPIBox", {
                                  attrs: {
                                    title: update_frequency,
                                    box_name: "box.name",
                                    data: _vm.kpi_data[update_frequency],
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("div", [_vm._v("No data found")]),
              ]),
            ]
          ),
        ]),
        _c("br"),
        _c("div", { staticClass: "selected-kpis-row" }, [
          _c("div", { staticClass: "selected-kpis-box" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Selected KPIs")]),
            _vm.getAllKpiNames().length
              ? _c(
                  "div",
                  { staticClass: "selected-kpis-list" },
                  _vm._l(_vm.getAllKpiNames(), function (kpi, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "selected-kpi-item" },
                      [
                        _c("span", { staticClass: "kpi-name" }, [
                          _vm._v(_vm._s(kpi)),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "button is-small is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.removeKpi(kpi)
                              },
                            },
                          },
                          [_vm._m(0, true)]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "no-kpis" }, [
                  _vm._v("No KPIs selected"),
                ]),
          ]),
        ]),
      ]),
      _c("hr"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-times" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }