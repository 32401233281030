import { render, staticRenderFns } from "./Base100Top8Triple.vue?vue&type=template&id=cfe1bce4"
import script from "./Base100Top8Triple.vue?vue&type=script&lang=js"
export * from "./Base100Top8Triple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cfe1bce4')) {
      api.createRecord('cfe1bce4', component.options)
    } else {
      api.reload('cfe1bce4', component.options)
    }
    module.hot.accept("./Base100Top8Triple.vue?vue&type=template&id=cfe1bce4", function () {
      api.rerender('cfe1bce4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/visualisations/bar/Base100Top8Triple.vue"
export default component.exports