import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import helper from './helper';
import cache from './cache';
import axios from 'axios';
import VueGtag from 'vue-gtag';

import cookies from 'hardtack';

import 'core-js/stable';
import 'bulma';
import 'bulma-extensions/dist/css/bulma-extensions.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'flag-icon-css/css/flag-icon.css';
import './prototypes.js';

/* Custom CSS */
import '@/assets/css/main.css';

/* Make the cookies available globally */
Vue.prototype.$cookies = cookies;

/* Main HTTP instance - used for loading pages in the router */
const http = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 12000,
	withCredentials: true
});

class AxiosCacheWrapper {
	get(...args) {
		const is_forced = args[0] === 'force' || navigator.userAgent.includes('Edge');
		if (args[0] === 'force') args.shift();

		const url = `${args[0]}${JSON.stringify(args[1])}`;
		if (cache[url] && !is_forced) {
			const copy = this.deep_copy(cache[url]);
			return new Promise(res => res(copy));
		}

		return http.get(...args);
	}

	post(...args) {
		const is_forced = args[0] === 'force' || navigator.userAgent.includes('Edge');
		if (args[0] === 'force') args.shift();
		
		const url = `${args[0]}${JSON.stringify(args[1])}`;
		if (cache[url] && !is_forced) {
			let copy = this.deep_copy(cache[url]);
			return new Promise(res => res(copy));
		}
		return http.post(...args);
	}

	deep_copy = arg => JSON.parse(JSON.stringify(arg));
}

Vue.prototype.$http = new AxiosCacheWrapper();

/* Commenting/Observation HTTP instance - used for loading pages in the commenting widget */
Vue.prototype.$chttp = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 10000,
	withCredentials: true
});

const interceptor_error = (error) => {
	if (window.location.href.includes('presentation_auth')) {
		return error;
	} else if (error.response !== undefined) {
		switch (error.response.status) {
			case 401:
				localStorage.setItem('last_visited', JSON.stringify({
					path: router.currentRoute.path
				}));
				window.location.href = '/login';
				break;
			case 403:
				router.push('/access-denied');
				break;
		}
	}

	store.state.loading--;
	return error;
}

const comments_interceptor_error = (error) => {
	if (error.response !== undefined) {
		switch (error.response.status) {
			case 401:
				localStorage.setItem('last_visited', JSON.stringify({
					path: router.currentRoute.path
				}));
				window.location.href = '/login';
				break;
			case 403:
				break;
		}
	}

	store.state.commenting.loading--;
	return error;
}

/* Request Interceptor */
http.interceptors.request.use(
	config => {
		store.state.loading++;
		return config;
	},
	interceptor_error
);
/* Response Interceptor */
http.interceptors.response.use(
	config => {
		const url = `${config.config.url}${config.config.data}`;
		cache[url] = JSON.parse(JSON.stringify(config)); // Deep copy

		store.state.loading--;
		return config;
	},
	interceptor_error
);

/* Request Interceptor */
Vue.prototype.$chttp.interceptors.request.use(
	config => {
		store.state.commenting.loading++;
		return config;
	},
	comments_interceptor_error
);

/* Response Interceptor */
Vue.prototype.$chttp.interceptors.response.use(
	config => {
		store.state.commenting.loading--;
		return config;
	},
	comments_interceptor_error
);

Vue.prototype.$helper = helper;

Vue.config.productionTip = false;
 
// Google analytics setup
Vue.use(VueGtag, {
	config: { id: 'UA-154855450-1' }
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');