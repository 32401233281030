var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.kpi_area
    ? _c(
        "div",
        { key: _vm.componentKey },
        [
          _c("Title", {
            attrs: {
              title: _vm.response_data.kpi_title,
              location: this.$route.fullPath,
              from_pbi: "",
            },
          }),
          _vm.disabled_areas.includes(_vm.kpi_area) && _vm.isProduction
            ? _c("div", [
                _c("img", {
                  staticClass: "assembled",
                  attrs: {
                    src: "/assets/images/presentation/being-assembled.png",
                  },
                }),
              ])
            : _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "options-bar" },
                    [
                      _c("Buttons", {
                        staticClass: "mt1p5 is-pulled-left",
                        attrs: {
                          buttons: _vm.generate_buttons(),
                          clickHandler: _vm.setReportSection,
                        },
                      }),
                      _vm.kpi_area === "market-view"
                        ? _c(
                            "button",
                            {
                              class: [
                                "button ikea-blue mvfy24btn",
                                {
                                  "router-link-active":
                                    _vm.isMarketytdButtonActive,
                                },
                              ],
                              attrs: {
                                title: "Click to open FY-24 Power BI Report",
                              },
                              on: { click: _vm.marketviewclick },
                            },
                            [_vm._v(" Market View FY24 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.disabled_button
                    ? _c("div", [
                        _c("p", [_vm._v("No data available for Current Year")]),
                      ])
                    : _c("EmbeddedAnalytics", {
                        key: _vm.embed_key,
                        attrs: {
                          group_id: _vm.group_id,
                          report_id: _vm.report_id,
                          report_section: _vm.report_section,
                          pbi_filter: _vm.pbi_filter,
                        },
                      }),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }