var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      {
        staticClass: "navbar is-fixed-top",
        attrs: {
          id: "navbar",
          role: "navigation",
          "aria-label": "main navigation",
        },
      },
      [
        _c(
          "div",
          { staticClass: "navbar-brand" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-item home-button", attrs: { to: "/" } },
              [
                _c("img", {
                  attrs: { src: "/assets/images/home/IKEA-Text.svg" },
                }),
                _vm.$store.state.selected.market === "TI"
                  ? _c("div", { staticClass: "ml small-screen-disable" }, [
                      _vm._v(" Ingka Flight Tower "),
                    ])
                  : _c("div", { staticClass: "ml small-screen-disable" }, [
                      _vm._v("Retail Flight Tower"),
                    ]),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "navbar-burger burger",
                class: { "is-active": _vm.mobile_menu_active },
                attrs: {
                  role: "button",
                  "aria-label": "menu",
                  "aria-expanded": "false",
                  "data-target": "navbarBasicExample",
                },
                on: {
                  click: function ($event) {
                    _vm.mobile_menu_active = !_vm.mobile_menu_active
                  },
                },
              },
              [
                _c("span", { attrs: { "aria-hidden": "true" } }),
                _c("span", { attrs: { "aria-hidden": "true" } }),
                _c("span", { attrs: { "aria-hidden": "true" } }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "navbar-menu",
            class: { "is-active": _vm.mobile_menu_active },
          },
          [
            _vm.$store.state.selected.market != "TI"
              ? _c(
                  "div",
                  {
                    staticClass: "navbar-start",
                    on: {
                      click: function ($event) {
                        _vm.mobile_menu_active = false
                      },
                    },
                  },
                  [
                    _c("KpiDropdown"),
                    _c("KpiSearchField", { attrs: { menuItems: _vm.options } }),
                    _vm.$store.state.global.legacy_menu
                      ? _c(
                          "router-link",
                          {
                            staticClass: "navbar-item",
                            attrs: { to: "/sitemap" },
                          },
                          [_vm._v(" KPIs ")]
                        )
                      : _vm._e(),
                    _c("MenuExtras", { staticClass: "extras-pc-disable" }),
                    _c(
                      "div",
                      { staticClass: "display-contents extras-mobile-disable" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            on: {
                              click: function ($event) {
                                return _vm.route_to(`/country-overview`)
                              },
                            },
                          },
                          [_c("div", [_vm._v("Country Overview")])]
                        ),
                        _vm.$store.getters.check_access([
                          "country-insights",
                          "*",
                          "r",
                        ])
                          ? _c(
                              "a",
                              {
                                staticClass: "navbar-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.route_to(
                                      `/country-insights/introduction/${_vm.$store.state.selected.market}`
                                    )
                                  },
                                },
                              },
                              [_c("div", [_vm._v("Insights")])]
                            )
                          : _vm._e(),
                        false && _vm.$store.state.global.is_ift_user
                          ? _c(
                              "a",
                              {
                                staticClass: "navbar-item",
                                attrs: {
                                  href: "https://ingka.flight-tower.com/",
                                },
                              },
                              [_vm._v(" Ingka Flight Tower ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "navbar-item item-hover",
                        on: {
                          click: function ($event) {
                            return _vm.route_to("/exports")
                          },
                        },
                      },
                      [_vm._v("Exports")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "navbar-end" },
              [
                _vm.$store.state.selected.market != "TI" &&
                _vm.$store.getters.access_super_user != 0 &&
                _vm.$store.getters.access_super_user != null
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "navbar-item market-view-button ikea-blue vaps_report",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "white" },
                            attrs: { href: _vm.getVapsPerformanceUrl() },
                          },
                          [_vm._v("VAPS Performance")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.$store.state.selected.market != "TI" &&
                _vm.$store.getters.access_super_user != 0 &&
                _vm.$store.getters.access_super_user != null
                  ? _c(
                      "div",
                      {
                        staticClass: "navbar-item market-view-button ikea-blue",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "white" },
                            attrs: { href: _vm.getMarketViewUrl() },
                          },
                          [_vm._v("Market View")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("MainFilter"),
                _c("NotificationsAccordion"),
                _c(
                  "div",
                  { staticClass: "navbar-item" },
                  [_c("HelpDropDown")],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _vm.$store.state.global.show_observations
      ? _c("div", { key: _vm.renderkey }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    this.commenting_width === 0 &&
                    _vm.can_see_observations_tab(),
                  expression:
                    "this.commenting_width === 0 && can_see_observations_tab()",
                },
              ],
              staticClass: "observations-tab",
              on: { click: _vm.toggle_comment_widget },
            },
            [
              _c("i", { staticClass: "far fa-comment-dots" }),
              _vm._v(" Insights "),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }